import React from 'react';
import { flushSync } from 'react-dom';

import {defaults as defaultControls, ScaleLine, Zoom, Attribution, Rotate} from 'ol/control.js';
import Map from 'ol/Map';
import View from 'ol/View';
import * as olExtent from 'ol/extent';
import LayerGroup from 'ol/layer/Group';
import {toLonLat} from 'ol/proj';

import NCHeader from './components/header.js';
import NCFooter from './components/footer.js';
import OLMap from './components/map/map.js';
import MapMenus from './components/menu/map-menus.js';
import MapClickPopup from './components/map-click-popup/map-click-popup.js';

import WMSCapabilitiesHandler from './utilities/capabilities-handler-wms.js';
import DimensionControl from './utilities/dimension-state-controller.js';
import { LAYERS, DEFAULT_MAP_POSITIONS, DEFAULT_BASEMAP, ZONE_LAYERS, ZONE_FORECAST_LAYERS, BREAKPOINT_VALUES, CONVECTIVE_LAYERS, OL_ZINDEXES } from './config.js';

import olms from 'ol-mapbox-style';

// Maps NWS' QPE Service's raster functions to the idp_subsets which must match in /Identify requests
// Note that there are 4 regions co, hi, ak, pr which MUST be appended to the subset value ie. mrms_01h_co
// in order for the requests to work
const MRMS_QPE_RASTER_FUNCS_TO_SUBSETS = {
    "rft_1hr": "mrms_01h",
    "rft_3hr": "mrms_03h",
    "rft_6hr": "mrms_06h",
    "rft_12hr": "mrms_12h",
    "rft_24hr": "mrms_24h",
    "rft_48hr": "mrms_48h",
    "rft_72hr": "mrms_72h",
}

const MARINE_ZONE_PREFIXES = [
    "AMZ",
    "ANZ",
    "GMZ",
    "PHZ",
    "PKZ",
    "PMZ",
    "PZZ"
]

const ZINDEXES = { // for viewer React components (not OL Layer zIndexes)
        'ol_map': 0,
        'header': 50,
        'layer_menu': 200,
        'animation_control': 500,
        'footer': 55,
        'map_click_popup': 999
};
// animation speed clickaway menu has zIndex: '1100' hardcoded

const MULTIPLE_URL_LAYERS = [
    "ndfd",
    "s111",
    "nbs",
    "s100",
    "satellite",
    "mrms_qpe",
    "sst",
    "zone_forecasts",
    "convective_outlooks",
    "vlm"
];

class NCMapViewerApp extends React.Component{
    constructor (props) {
        super();

        this.state = {
            // Object which maps all OL layer names to an object containing on, currentSource, layersParam, stylesParam
            // Keys of olLayerState correspond the union of all OpenLayers layer names within LAYERS object within config.js
            // (Within LAYERS each product has one or more layer names found in LAYERS[<product>].layers)
            //
            // on:            holds true/false to display the layer or not
            // currentSource: holds a string containing the name of the current OpenLayers source to be used by the layer
            // layersParam:   holds the value to be used within the OpenLayers Source object's 'layers' parameter
            // stylesParam:   holds the value to be used within the OpenLayers Source object's 'styles' parameter
            //
            // ToDo: Consider adding 'protocol' to each layer in config.js indicating 'WMS' or 'WMTS' and creating a WMTSSource react component (and store protocol in olLayerState for each layer)
            // That would provide a structured way of answering the question of whether or not this is
            // a WMS or WMTS source. We need to know to initialize this object since we are looking into
            // the OL source object and need to know what key to use/how to look. But rather than redo
            // config over that one question, I am using this temporary check below. Also We have the same issue
            // when accessing source objects within source.js. We could use the protocol flag to decide
            // whether to make a wms or wmts source component. But for now there is a similar workaround in
            // source.js to what we have below
            //
            // NOTE: layersParam currently can be list or string (list for need of dynamicLayersList behavior)
            // It will initialize to whatever type was used in config.js (most happen to be strings) We may want
            // to keep this behavior to help differentiate between layers that need dynamic layer lists and those
            // that always specify a single layer in layers param. Or make everything conform to lists and treat
            // everything the same (at the cost of extra source renders to change layersParam for some products)
            olLayerState: (() => {
                let curLayerState = {};
                for (const product in LAYERS) {
                    for (const olLayer in LAYERS[product].layers){
                        // populate each olLayerState member by collecting info from LAYERS obj in config.js
                        const defaultSource = LAYERS[product].layers[olLayer].defaultSource;
                        let sourceLayersParam = null;
                        let sourceStylesParam = LAYERS[product].layers[olLayer].sources[defaultSource].sourceObj.style_;

                        if (sourceStylesParam || product === 'surface_obs') {
                            // This is a WMTS source object
                        } else {
                            // This is a WMS source object
                            sourceLayersParam = LAYERS[product].layers[olLayer].sources[defaultSource].sourceObj.params_.LAYERS;
                            sourceStylesParam = LAYERS[product].layers[olLayer].sources[defaultSource].sourceObj.params_.STYLES;
                        }

                        let curDetails = {
                            // If no initialState is set for an individual layer, then fall back to initialState of the product
                            'on': ('initialState' in LAYERS[product].layers[olLayer]) ? LAYERS[product].layers[olLayer].initialState : LAYERS[product].initialState, //YOU MUST ADD initialState TO config.js FOR EACH LAYER (next to defaultSource)
                            'layersParam': (sourceLayersParam) ? sourceLayersParam : [],
                            'stylesParam': (sourceStylesParam) ? sourceStylesParam : [],
                            'currentSource': defaultSource,
                        };

                        Object.assign(curLayerState, {[olLayer]: curDetails});
                    }
                }
                return (curLayerState);
            })(),
            // state of all products - true/false for on/off
            // In the menu, products each have their own menu component and a switch that can toggle
            // all layers for the product on/off
            productToggles: (() => {
                let curProductToggles = {};
                for (const product in LAYERS) {
                    Object.assign(curProductToggles, {[product]: LAYERS[product].initialState});
                }
                return (curProductToggles);
            })(),
            // Initialization status of datasets that must wait on Get Capabilities requests to be enabled
            initializedCaps: (() => {
                let curInitializedCaps = {};
                for (const product in LAYERS) {
                    if (LAYERS[product].capUrls) {
                        // If layer is animated set to false
                        Object.assign(curInitializedCaps, {[product]: (!LAYERS[product].animated)});
                    }
                }
                return (curInitializedCaps);
            })(),
            // Store all known time values of animation data organized by toggle-able products
            // mapped to objects that map layer names to their list of time values from capabilities
            // (this is updated by only by "capabilitiesUpdated" event and consumed only by time-control)
            timeValues: {},
            // Store all known legend/style info to help display legends
            // (keys should be a subset of layerToggle's keys)
            // (sub keys correspond to the layer name in capabilities that style/legend info belongs to)
            // styleInfo has the following format
            //{ <product/layer name> :
            //    { <OL layer name> :
            //        [
            //            { name: <name of style parsed from capabilities>,
            //              title: <value parsed from capabilities - used as label in viewer>,
            //              width: <width (int) parsed from capabilities>,
            //              height: <height (int) parsed from capabilities>,
            //              format: <format (ex img/png) parsed from capabilities>,
            //              url: <url of legend image parsed from capabilities>,
            //            }, ...
            //        ]
            //    }, ...
            //}
            styleInfo: (() => {
                let curStyleInfo = {};
                for (const product in LAYERS) {
                    if (LAYERS[product].capUrl) { // Invariant? If it has capUrl it will have style info
                        Object.assign(curStyleInfo, {[product]: null});
                    }
                }
                return (curStyleInfo);
            })(),
            // Store the opacity of each layer
            layerOpacities: (() => {
                let curOpacities = {};
                for (const product in LAYERS) {
                    Object.assign(curOpacities, {[product]: LAYERS[product].opacity});
                }
                return (curOpacities);
            })(),
            // Store state of MapClickPopup container: true for on, false for off
            mapClickPopupOn: false,
            // Store coordinates of last map click {x: coordinate, y: coordinate}
            mapClickCoords: {x:0, y:0},
            // Store latest response data for all requests made by clicking the map (getFeatureInfo + point forecasts)
            // Maps layer groups to list of all json responses paired with the coords associated with each response
            // ex. {ndfd: [{url: "", response: {}, coords: {x: 12.1233213, y: 12.1231231}]}
            mapClickInfo: {},
            // Store selectedTime for last map click
            mapClickTime: null,
            // Store current time if time slider is on, else null (int, epoch time in milliseconds)
            selectedTime: null,
            // Store all info on custom layers that have been added by user
            customLayerInfo: {},
            // Store state of layer menu on/off
            layerMenuOn: false,
            // Store state of legend menu on/off
            legendMenuOn: false,
            // Store previous menu state at time of mapClickPopup being turned on so we know what to do when it is closed
            popupPrevMenuState: null,
            // Toggle that causes olLayers to be re-added to map when changed
            refreshLayers: false,
            // Store general info/metadata about products that must be parsed from capabilities
            // Initialize with keywords from config.js. Has form: {[product]: {keywords: [], wmsCapUrls: [{url: "", title: ""}] }},...}
            productInfo: (() => {
                let curProductInfo = {};
                for (const product in LAYERS) {
                    if (LAYERS[product].keywords) {
                        Object.assign(curProductInfo, {[product]: {keywords: LAYERS[product].keywords}});
                    }
                }
                return (curProductInfo);
            })(),
        };

        // Instantiation of all persistent class objects needed for app
        // Open Layers Map object
        this.scaleLine = new ScaleLine();
        this.scaleLine.setUnits('us');
        this.zoom = new Zoom();
        this.attribution = new Attribution({collapsible: true});
        this.olMap = new Map({
            controls: [this.scaleLine, this.zoom, this.attribution],
            target: null,
            serverType: 'geoserver',
            maxTilesLoading: 10,
            layers: [],
            pixelRatio: 1,
            view: new View({enableRotation: false}),
        });

        // Create callback function for removing the zoom control
        // Arg: showZoom (bool) - true to show, false to remove
        this.showZoom = (zoomOn) => {
            let controlExists = false;
            this.olMap.getControls().forEach((control) => {
                if (control instanceof Zoom) {
                    controlExists = true;
                }
            });

            if (zoomOn && !controlExists) {
                this.olMap.addControl(this.zoom);
            }

            if (!zoomOn && controlExists) {
                this.olMap.removeControl(this.zoom);
            }
        };

        // Vector Tiles created from an openlayers Mapbox Style object
        // olms returns a promise which resolves when it completes
        // This low-level API does not create a source for the layer
        // https://github.com/openlayers/ol-mapbox-style
        const apiKey = process.env.REACT_APP_API_KEY
        const baseUrl = "https://basemapstyles-api.arcgis.com/arcgis/rest/services/styles/v2/styles"
        this.url = (name) => `${baseUrl}/${name}?type=style&token=${apiKey}&language=en`;

        // Set basemap layer once as part of map initialization
        olms(this.olMap, this.url(DEFAULT_BASEMAP));

        // Register event handler for mapClicks/touches
        this.olMap.on('singleclick', (evt) => {
            //DEBUG
            //console.log("--> Clicked Map Coords: " + evt.coordinate);
            //console.log(evt);
            var evtCoordinateX = evt.coordinate[0]
            var evtCoordinateY = evt.coordinate[1]
            var checkCoordinates = olExtent.containsXY(
                                   [-20037508.342789244, -20048966.1040146, 20037508.342789244, 20048966.104014594],
                                   evtCoordinateX, evtCoordinateY)

            //Normalize the longitude if outside the range
            if (!checkCoordinates) {

                const web_mercator_maxX = 20037508.342789244

                while(evtCoordinateX < -web_mercator_maxX){
                  evtCoordinateX += 2 * web_mercator_maxX;
                }
                while (evtCoordinateX > web_mercator_maxX){
                  evtCoordinateX -= 2 * web_mercator_maxX;
                }
            }

            flushSync(() => {
                this.updateMapClickCoords(evtCoordinateX, evtCoordinateY);
            });

            this.updateMapClickTime(this.state.selectedTime);
            // Gather all URLs relevant to this map-click then turn on popup
            let newMapClickInfo = {};
            const viewResolution = this.olMap.getView().getResolution();
            for (let product in this.state.productToggles) {
                if (this.state.productToggles[product]) {
                    for (let olLayerName in LAYERS[product].layers) {
                        const olSourceName = this.state.olLayerState[olLayerName].currentSource
                        try {
                            let url = null;
                            // Do not call geFeatureInfoUrl from external WMS/WFS sources sources
                            if (product !== "zone_forecasts" && product !== "convective_outlooks" && product !== "surface_obs"
                                && olLayerName !== "cwa_boundaries" && olLayerName !== "river_forcast_centers") {

                                url = LAYERS[product].layers[olLayerName].sources[olSourceName].sourceObj.getFeatureInfoUrl(
                                    [this.state.mapClickCoords.x, this.state.mapClickCoords.y],
                                    viewResolution,
                                    'EPSG:3857',
                                    {'INFO_FORMAT': 'application/json', 'FEATURE_COUNT': '50'}
                                );
                            }
                            let infoUpdate = {url: url, coords: {x: this.state.mapClickCoords.x, y: this.state.mapClickCoords.y}, data: {}};
                            if (product === "mrms_qpe") {
                                const xVal = this.state.mapClickCoords.x;
                                const yVal = this.state.mapClickCoords.y;
                                const rasterFunc = this.state.olLayerState[olLayerName].layersParam.split(':')[1];
                                const urlPart1 = "https://mapservices.weather.noaa.gov/raster/rest/services/obs/mrms_qpe/ImageServer/identify?f=json&geometryType=esriGeometryPoint&geometry=%7B%22spatialReference%22%3A%7B%22latestWkid%22%3A3857%2C%22wkid%22%3A102100%7D%2C%22x%22%3A";
                                const urlPart2 = "%2C%22y%22%3A";
                                const urlPart3 = "%7D&mosaicRule=%7B%22ascending%22%3Afalse%2C%22where%22%3A%22idp_subset%3D%27";
                                const urlPart4 = "%27%22%2C%22mosaicMethod%22%3A%22esriMosaicAttribute%22%2C%22mosaicOperation%22%3A%22MT_FIRST%22%2C%22sortField%22%3A%22StdTime%22%2C%22sortValue%22%3A%220%22%7D&renderingRules=%5B%7B%22rasterFunction%22%3A%22";
                                const urlPart5 = "%22%7D%5D&pixelSize=%7B%22spatialReference%22%3A%7B%22latestWkid%22%3A3857%2C%22wkid%22%3A102100%7D%2C%22x%22%3A4891.96981024998%2C%22y%22%3A4891.96981024998%7D&returnGeometry=false&returnCatalogItems=true&returnPixelValues=true&maxItemCount=1&processAsMultidimensional=false";

                                if (!newMapClickInfo[product]) { // If this is first region, then create object
                                    newMapClickInfo[product] = {urls: {}, coords: {x: this.state.mapClickCoords.x, y: this.state.mapClickCoords.y}, data: {}};
                                }

                                // Make one URL for each region: co, hi, ak, pr
                                const regions = ["co", "ak", "hi", "pr"];
                                regions.forEach((region) => {
                                    const idpSubset = MRMS_QPE_RASTER_FUNCS_TO_SUBSETS[rasterFunc] + '_' + region;
                                    const mrmsQpeUrl = urlPart1 + xVal + urlPart2 + yVal + urlPart3 + idpSubset + urlPart4 + rasterFunc + urlPart5;
                                    newMapClickInfo[product].urls[region] = mrmsQpeUrl;
                                    newMapClickInfo[product].data[region] = null;
                                });
                            } else if (product === "tropical_cyclones") {
                                const cycloneInfoUrls = {};
                                if (!newMapClickInfo[product]) {
                                    newMapClickInfo[product] = {urls: {}, coords: {x: this.state.mapClickCoords.x, y: this.state.mapClickCoords.y}, data: {}};
                                }

                                if (olLayerName === "tropical_ss" && this.state.olLayerState[olLayerName].on) {
                                    newMapClickInfo[product].urls = {[olLayerName]: url, ...newMapClickInfo[product].urls}
                                }

                                if (olLayerName !== "tropical_ss") { // Handle tropical_cyclones feature info url creation
                                    // Build all required urls:
                                    // We have a feature info url with individual layers in a comma separated string. The same layer list/string
                                    // is present for QUERY_LAYERS and LAYERS parameters. Goal here is to recreate the same url
                                    // for each layer in the list using only one layer name. The parse first breaks off the query portion
                                    // of the url by splitting on ?. Then it parses each parameter in the query and builds all the urls. It
                                    // should be able to handle the parameters in any order, it just requires that LAYERS and QUERY_LAYERS
                                    // are present and have the same value

                                    let [urlPath, urlQuery] = url.split('?');
                                    let queryTokens = urlQuery.split('&'); // List of each GET Param in query
                                    let otherParams = urlPath + "?"; // String holding params that come either before or between QUERY_LAYERS and LAYERS
                                    let layers;
                                    let queryLayersParamComplete = false;
                                    let layersParamComplete = false;
                                    let index = 0;

                                    for (const token of queryTokens) {
                                        const ampersand = (index === 0) ? "" : "&"; //First token should not be preceded by &
                                        if (token.split("=")[0] === "LAYERS") {
                                            layers = token.slice(7);
                                            for (const layer of layers.split('%2C')) {
                                                if (!cycloneInfoUrls.hasOwnProperty(layer)) {cycloneInfoUrls[layer] = ""}
                                                cycloneInfoUrls[layer] = cycloneInfoUrls[layer] + otherParams + ampersand + "LAYERS=" + layer;
                                            }
                                            otherParams = "";
                                            layersParamComplete = true;
                                        }else if (token.split("=")[0] === "QUERY_LAYERS") {
                                            layers = token.slice(13);
                                            for (const layer of layers.split('%2C')) {
                                                if (!cycloneInfoUrls.hasOwnProperty(layer)) {cycloneInfoUrls[layer] = ""}
                                                cycloneInfoUrls[layer] = cycloneInfoUrls[layer] + otherParams + ampersand + "QUERY_LAYERS=" + layer;
                                            }
                                            otherParams = "";
                                            queryLayersParamComplete = true;
                                        // Next two cases cover what to do if the current token/param is not LAYERS or QUERY_LAYERS
                                        // We are concatenating the param to otherParams if we havnt seen both LAYERS or QUERY_LAYERS yet
                                        // If we have already handled both LAYERS and QUERY_LAYERS then we use final branch where
                                        // we just add the param onto the end of each url
                                        }else if (!queryLayersParamComplete || !layersParamComplete){
                                            otherParams = otherParams + ampersand + token;
                                        }else {
                                            for (const layer in cycloneInfoUrls) {
                                                cycloneInfoUrls[layer] = cycloneInfoUrls[layer] + ampersand + token;
                                            }
                                        }
                                        index += 1;
                                    }
                                    newMapClickInfo[product].urls = Object.assign(newMapClickInfo[product].urls, cycloneInfoUrls);
                                }
                            } else if (product === "ndfd") {
                                // NDFD has 5 OLlayers (one from each region) representing
                                // one product/productToggles key ('ndfd'). It needs to query all 5 layers for feature info
                                // so its mapClickInfo obj is structured like tropical_cyclones where it has
                                // 'urls' key which will map to an object that maps olLayerNames to their urls.
                                // The 'data' key will also map to an object that maps olLayerNames to the json data
                                // returned by getFeatureInfo
                                // NDFDFeatureInfo component will have to hardcode these keys (format: "ndfd_region" which matches
                                // OL layer keys used in config.js
                                if (!newMapClickInfo[product]) { // If this is first ndfd layer group, then create object
                                    newMapClickInfo[product] = {urls: {}, coords: {x: this.state.mapClickCoords.x, y: this.state.mapClickCoords.y}, data: {}};
                                }
                                newMapClickInfo[product].urls[olLayerName] = url;
                                // Adding key to data object before async requests are made. This guarantees that all keys will
                                // be in place which helps the ndfd-feature-info component know whether queries are done or not
                                newMapClickInfo[product].data[olLayerName] = {};
                            } else if (product === "s111" || product === "sst" || product === "vlm") {
                                // Need one URL for each ofs service
                                if (!newMapClickInfo[product]) { // If this is first s111 layer group, then create object
                                    newMapClickInfo[product] = {urls: {}, coords: {x: this.state.mapClickCoords.x, y: this.state.mapClickCoords.y}, data: {}};
                                }
                                newMapClickInfo[product].urls[olLayerName] = url;
                                // Adding key to data object before async requests are made. This guarantees that all keys will
                                // be in place which helps the ofs-feature-info component know whether queries are done or not
                                newMapClickInfo[product].data[olLayerName] = {};
                            } else if (product === "satellite") {
                               if (!newMapClickInfo[product]) { // If this is first satellite then create object
                                    newMapClickInfo[product] = {urls: {}, coords: {x: this.state.mapClickCoords.x, y: this.state.mapClickCoords.y}, data: {}};
                                }
                                newMapClickInfo[product].urls[olLayerName] = url;
                                newMapClickInfo[product].data[olLayerName] = {};
                            } else if (product === "nbs") {
                                // Need one URL for bathy and one for tile scheme, but we only want tile scheme if it is toggled on
                                if (olLayerName === "bluetopo_tile_scheme" && !this.state.olLayerState.bluetopo_tile_scheme.on) continue;
                                if (!newMapClickInfo[product]) {
                                    newMapClickInfo[product] = {urls: {}, coords: {x: this.state.mapClickCoords.x, y: this.state.mapClickCoords.y}, data: {}};
                                }
                                if (url.includes('STYLES=nbs_elevation')) {
                                    url = url.replace('STYLES=nbs_elevation', 'STYLES=source_institution')
                                }
                                if (url.includes('STYLES=nbs_uncertainty')) {
                                    url = url.replace('STYLES=nbs_uncertainty', 'STYLES=source_institution')
                                }
                                newMapClickInfo[product].urls[olLayerName] = url;
                                newMapClickInfo[product].data[olLayerName] = {};
                            } else if (product === "s100") {
                                // Need one URL for each s100 service
                                if (!newMapClickInfo[product]) { // If this is first s100 layer group, then create object
                                    newMapClickInfo[product] = {urls: {}, coords: {x: this.state.mapClickCoords.x, y: this.state.mapClickCoords.y}, data: {}};
                                }
                                url = LAYERS[product].layers[olLayerName].sources[olSourceName].sourceObj.getFeatureInfoUrl(
                                    [this.state.mapClickCoords.x, this.state.mapClickCoords.y],
                                    viewResolution,
                                    'EPSG:3857',
                                    {'INFO_FORMAT': 'application/json', 'FEATURE_COUNT': '1'}
                                );
                                newMapClickInfo[product].urls[olLayerName] = url;
                                newMapClickInfo[product].data[olLayerName] = {};
                            } else if (product === "federal_agency_boundaries"){
                                // Manually create one URL for each federal boundary layer. Query URL returns GeoJSON
                                if (!this.state.olLayerState[olLayerName].on) continue;

                                if (!newMapClickInfo[product]) { // If this is first federal boundary layer group, then create object
                                    newMapClickInfo[product] = {urls: {}, coords: {x: this.state.mapClickCoords.x, y: this.state.mapClickCoords.y}, data: {}};
                                }

                                if(olLayerName !== "military_boundaries" && olLayerName !== "tc_ww_breakpoints" && olLayerName !== "ss_ww_communication_points") {
                                    let layerCoords = newMapClickInfo[product].coords.x.toString() + "," + newMapClickInfo[product].coords.y.toString();
                                    const featureServerUrl = "https://mapservices.weather.noaa.gov/static/rest/services/nws_reference_maps/nws_reference_map/FeatureServer/"
                                    let queryUrl = "/query?geometryType=esriGeometryPoint&returnGeometry=true&f=geojson&outSR=102100&outFields=*&geometry=" + layerCoords;

                                    newMapClickInfo[product].urls[olLayerName] = featureServerUrl + ZONE_LAYERS[olLayerName].id + queryUrl;
                                } else {
                                    newMapClickInfo[product].urls[olLayerName] = url;
                                }

                                newMapClickInfo[product].data[olLayerName] = {};
                            } else if (product === "zone_forecasts"){
                                // Manually create one URL for each zone forecast layer. Query URL returns GeoJSON
                                if (!newMapClickInfo[product]) { // If this is first zone forecast layer group, then create object
                                    newMapClickInfo[product] = {urls: {}, coords: {x: this.state.mapClickCoords.x, y: this.state.mapClickCoords.y}, data: {}};
                                }
                                if(this.state.olLayerState[olLayerName].on) {
                                    let layerCoords = newMapClickInfo[product].coords.x.toString() + "," + newMapClickInfo[product].coords.y.toString();
                                    let serverUrl = "https://mapservices.weather.noaa.gov/static/rest/services/nws_reference_maps/nws_reference_map/FeatureServer/"
                                    let queryUrl = "/query?geometryType=esriGeometryPoint&returnGeometry=true&f=geojson&outSR=102100&outFields=*&geometry=" + layerCoords;
                                    if(ZONE_FORECAST_LAYERS[olLayerName].group === "beach") {
                                        let serverUrl = "https://mapservices.weather.noaa.gov/vector/rest/services/outlooks/marine_beachforecast_summary/MapServer/"
                                        let queryUrl = "/query?geometryType=esriGeometryPoint&returnGeometry=true&f=geojson&inSR=102100&outFields=*&geometry=" + layerCoords;
                                        if(ZONE_FORECAST_LAYERS[olLayerName].id === "0") {
                                            newMapClickInfo[product].urls[olLayerName] = serverUrl + ZONE_FORECAST_LAYERS[olLayerName].id + queryUrl;
                                            const day2Layer = olLayerName.replace(/.$/, '2');
                                            newMapClickInfo[product].urls[day2Layer] = serverUrl + ZONE_FORECAST_LAYERS[day2Layer].id + queryUrl;
                                        } else {
                                            newMapClickInfo[product].urls[olLayerName] = serverUrl + ZONE_FORECAST_LAYERS[olLayerName].id + queryUrl;
                                            const day1Layer = olLayerName.replace(/.$/, '1');
                                            newMapClickInfo[product].urls[day1Layer] = serverUrl + ZONE_FORECAST_LAYERS[day1Layer].id + queryUrl;
                                        }
                                    } else {
                                        newMapClickInfo[product].urls[olLayerName] = serverUrl + ZONE_FORECAST_LAYERS[olLayerName].id + queryUrl;
                                    }
                                    newMapClickInfo[product].data[olLayerName] = {};
                                }
                            } else if (product === "surface_obs") {
                                if (!newMapClickInfo[product]) { // If this is first surface obs layer group, then create object
                                    newMapClickInfo[product] = {urls: {},
                                        coords: {x: this.state.mapClickCoords.x, y: this.state.mapClickCoords.y},
                                        data: []
                                    };
                                }
                                // Refreshes source data on click
                                const obsStationSource = LAYERS['surface_obs'].layers['station_obs_scale6'].sources['station_obs_scale6'].sourceObj.source;
                                obsStationSource.refresh();
                                // Gets clicked feature extents to zoom to selected features
                                let newExtent = olExtent.createEmpty();
                                let totalCount = 0;
                                let obsFeatures = this.olMap.forEachFeatureAtPixel(evt.pixel,
                                    function(feature) {
                                        const extent = olExtent.createEmpty();
                                        let count = 0;
                                        for(const newFeature of feature.get('features')) {
                                            if(newFeature.getProperties()['status'] === 'active') {
                                                const featureExtent = newFeature.getGeometry().getExtent();
                                                count = count + 1;
                                                olExtent.extend(extent, featureExtent);
                                            }
                                        }
                                        return [extent, count]}, {
                                    layerFilter: function(layer) {
                                        return layer.getZIndex() === OL_ZINDEXES['surface_obs']
                                    }
                                })
                                let COOPSFeatures = this.olMap.forEachFeatureAtPixel(evt.pixel,
                                    function(feature) {
                                        const extent = olExtent.createEmpty();
                                        let count = 0;
                                        for(const newFeature of feature.get('features')) {
                                            const featureExtent = newFeature.getGeometry().getExtent()
                                            count = count + 1;
                                            olExtent.extend(extent, featureExtent);
                                        }
                                        if(count === 0) {
                                            return null;
                                        } else {
                                            return [extent, count];
                                        }}, {
                                        layerFilter: function(layer) {
                                        return layer.getZIndex() === OL_ZINDEXES['co_ops_stations']
                                    }
                                })
                                if(obsFeatures) {
                                    totalCount = totalCount + obsFeatures[1];
                                }
                                if(COOPSFeatures) {
                                    totalCount = totalCount + COOPSFeatures[1];
                                }
                                if(totalCount > 1) {
                                    if(obsFeatures && COOPSFeatures && totalCount > 2) {
                                        olExtent.extend(newExtent, obsFeatures[0]);
                                        olExtent.extend(newExtent, COOPSFeatures[0]);
                                    } else if(obsFeatures && obsFeatures[1] > 1) {
                                        olExtent.extend(newExtent, obsFeatures[0]);
                                    } else if(COOPSFeatures && COOPSFeatures[1] > 1) {
                                        olExtent.extend(newExtent, COOPSFeatures[0]);
                                    }
                                    this.olMap.getView().fit(newExtent, {duration: 500, padding: [50, 50, 50, 50], maxZoom: 12, nearest: true});
                                }
                            } else if (product === "convective_outlooks"){
                                if (!newMapClickInfo[product]) { // If this is first convective layer group, then create object
                                    newMapClickInfo[product] = {urls: {}, coords: {x: this.state.mapClickCoords.x, y: this.state.mapClickCoords.y}, data: {}};
                                }

                                let layerCoords = newMapClickInfo[product].coords.x.toString() + "," + newMapClickInfo[product].coords.y.toString();
                                let serverUrl = "https://mapservices.weather.noaa.gov/vector/rest/services/outlooks/SPC_wx_outlks/MapServer/"
                                let queryUrl = "/query?geometryType=esriGeometryPoint&returnGeometry=true&f=geojson&outSR=102100&outFields=*&inSR=102100&geometry=" + layerCoords;
                                newMapClickInfo[product].urls[olLayerName] = serverUrl + CONVECTIVE_LAYERS[olLayerName].arc_id + queryUrl;
                                newMapClickInfo[product].data[olLayerName] = {};
                            } else { // Regular update: just assign the infoUpdate object to the product/point_forecast key
                                newMapClickInfo[product] = infoUpdate;
                            }
                        } catch {
                            continue;
                        }
                    }
                }
            }
            // ToDo: Add the maps current time (wutever the slider was on at the time of the click, else current time)
            // to the mapClickInfo object. This will let us display the time in the popup and not have the popup
            // re-rendering if timestate changes (also time is only available at the map and below, so need to refactor
            // to get it up to the map.)

            // Add an entry for point forecasts
            const [lon, lat] = toLonLat(evt.coordinate);
            const url = "https://api.weather.gov/points/" + lat + "," + lon;
            newMapClickInfo["point_forecast"] = {url: url, coords: {x: this.state.mapClickCoords.x, y: this.state.mapClickCoords.y}, data: {}};

            if (this.state.layerMenuOn) {
                this.setPopupPrevMenuState("layer");
            } else if (this.state.legendMenuOn) {
                this.setPopupPrevMenuState("legend");
            } else {
                this.setPopupPrevMenuState("none");
            }

            this.updateMapClickPopup(true);
            this.setLayerMenuOn(false);
            this.setLegendMenuOn(false);

            // Replace old mapClickInfo with new one containing just urls, coords, and empty data
            // Then start fetching all data
            this.updateMapClickInfo(newMapClickInfo);
            Object.keys(newMapClickInfo).forEach(async (key) => {
                if (key === "tropical_cyclones") {
                    const advisoryUrl = "";

                    try {
// Disabled due to CORS issue. Need to get advisory text bulletin url from currentStorms.json, Buuuut we will need to get it for each storm and somehow
// match it to features for that storm. Until then just hardcoding nhc website for url
//                        const response = await fetch("https://www.nhc.noaa.gov/CurrentStorms.json");
//                        const data = await response.text();
//                        console.log("currentStorms json:");
//                        console.log(JSON.parse(data));
                    } catch (e) {
                        console.log("Caught error trying to get current storms json:");
                        console.log(e);
                    }

                    for (const layerName in newMapClickInfo[key].urls) {
                        const response = await fetch(newMapClickInfo[key].urls[layerName]);
                        const data = await response.text();
                        newMapClickInfo[key].data[layerName] = JSON.parse(data);
                    }
                    // tropical cyclones need a link to the latest public advisory text bulletin, but it isnt provided
                    // in get feature info requests, so it will be requested here from currentStorms.json
                    // and shoved into the properties of every feature (but this is unavailable atm)
                } else if (key === "point_forecast") {
                    const errorMsg = {"error": "A point forecast is currently unavailable at the specified location. Please try again in another location or visit https://www.weather.gov for more information."};
                    // Must make request to weather.gov points api, then use URL in that response to get point forecast
                    try {
                        if (newMapClickInfo[key].url) {
                            let parsedPointData = null;
                            let parsedForecastData = null;
                            const attempts = 3;
                            for (let x = 0; x < attempts; x++) {
                                parsedPointData = await getData(newMapClickInfo[key].url);
                                if(parsedPointData.properties) {
                                    if(parsedPointData.properties.forecast) {
                                        parsedForecastData = await getData(parsedPointData.properties.forecast);
                                        if(parsedForecastData.status !== 500 && parsedForecastData.status !== 404 && parsedForecastData.status !== 503) {
                                            newMapClickInfo[key].data["point"] = parsedPointData;
                                            newMapClickInfo[key].data["forecast"] = parsedForecastData;
                                            break;
                                        }
                                    }
                                }
                            }
                            if(!newMapClickInfo[key].data["forecast"]) {
                                let layerCoords = newMapClickInfo[key].coords.x.toString() + "," + newMapClickInfo[key].coords.y.toString();
                                const featureServerUrl = "https://mapservices.weather.noaa.gov/static/rest/services/nws_reference_maps/nws_reference_map/FeatureServer/";
                                let queryUrl = "/query?geometryType=esriGeometryPoint&returnGeometry=false&f=geojson&outSR=102100&outFields=*&geometry=" + layerCoords;
                                const offshoreUrl = featureServerUrl + ZONE_FORECAST_LAYERS['offshore_zone_forecasts'].id + queryUrl;
                                const coastalUrl = featureServerUrl + ZONE_FORECAST_LAYERS['coastal_marine_zone_forecasts'].id + queryUrl;
                                const highSeasUrl = featureServerUrl + ZONE_FORECAST_LAYERS['high_seas_zone_forecasts'].id + queryUrl;
                                let zoneID = null;
                                if(parsedPointData.properties) {
                                    if(parsedPointData.properties.forecastZone) {
                                        zoneID = parsedPointData.properties.forecastZone.split("/").slice(-1);
                                    }
                                }
                                if(parsedForecastData && parsedForecastData.title) { //if forecast error message
                                    if(parsedForecastData.title === "Marine Forecast Not Supported") {
                                        //point is either offshore or coastal
                                        const parsedOffshoreData = await getData(offshoreUrl);
                                        if(parsedOffshoreData.features[0]) {
                                            newMapClickInfo[key].data["offshore"] = parsedOffshoreData;
                                        } else {
                                            //if coastal, feature info popup will include link for marine point forecast
                                            newMapClickInfo[key].data["point"] = parsedPointData;
                                            newMapClickInfo[key].data["marine_point"] = parsedForecastData;
                                        }
                                    } else if(parsedForecastData.title === "Unexpected Problem" || parsedForecastData.title === "Service Unavailable") {
                                        //if point API doesn't work, get the zone forecast
                                        //check if offshore or coastal, else return zone info
                                        if(MARINE_ZONE_PREFIXES.includes(zoneID[0].slice(0,3))) {
                                            const parsedOffshoreData = await getData(offshoreUrl);
                                            if(parsedOffshoreData.features[0]) {
                                                newMapClickInfo[key].data["offshore"] = parsedOffshoreData;
                                            } else {
                                                const parsedCoastalData = await getData(coastalUrl);
                                                if(parsedCoastalData.features[0]) {
                                                    newMapClickInfo[key].data["coastal"] = parsedCoastalData;
                                                } else {
                                                    newMapClickInfo[key].data["zone"] = parsedPointData;
                                                }
                                            }
                                        } else { newMapClickInfo[key].data["zone"] = parsedPointData; }
                                    }
                                } else if(parsedPointData.properties && parsedPointData.properties.forecastZone) { //if no forecast error message, but point has forecast zone
                                    if(MARINE_ZONE_PREFIXES.includes(zoneID[0].slice(0,3))) {
                                        const parsedOffshoreData = await getData(offshoreUrl);
                                        if(parsedOffshoreData.features[0]) {
                                            newMapClickInfo[key].data["offshore"] = parsedOffshoreData;
                                        } else {
                                            const parsedCoastalData = await getData(coastalUrl);
                                            if(parsedCoastalData.features[0]) {
                                                newMapClickInfo[key].data["coastal"] = parsedCoastalData;
                                            } else { newMapClickInfo[key].data["zone"] = parsedPointData; }
                                        }
                                    } else { newMapClickInfo[key].data["zone"] = parsedPointData; }
                                } else if(parsedPointData.title) { // if point data error message
                                    if(parsedPointData.title === "Unexpected Problem") {
                                        const parsedOffshoreData = await getData(offshoreUrl);
                                        if(parsedOffshoreData.features[0]) {
                                            newMapClickInfo[key].data["offshore"] = parsedOffshoreData;
                                        } else {
                                            const parsedCoastalData = await getData(coastalUrl);
                                            if(parsedCoastalData.features[0]) {
                                                newMapClickInfo[key].data["coastal"] = parsedCoastalData;
                                            } else {
                                                const parsedHighSeasData = await getData(highSeasUrl);
                                                if(parsedHighSeasData.features[0]) {
                                                    newMapClickInfo[key].data["highSeas"] = parsedHighSeasData;
                                                }
                                            }
                                        }
                                    } else if(parsedPointData.title === "Data Unavailable For Requested Point") {
                                        const parsedHighSeasData = await getData(highSeasUrl);
                                        if(parsedHighSeasData.features[0]) {
                                            newMapClickInfo[key].data["highSeas"] = parsedHighSeasData;
                                        }
                                    }
                                }
                            }
                        }
                    } catch (e) {
                        newMapClickInfo[key].data = errorMsg;
                    }
                } else if(key === "surface_obs") {
                    //for surface obs, data is pulled from OL feature in cache
                    let obsFeatures = [], COOPSFeatures = [];
                    obsFeatures = this.olMap.forEachFeatureAtPixel(evt.pixel,
                        function(feature) {
                            let features = [];
                            for(const newFeature of feature.get('features').splice(0, 20)) {
                                newFeature.setProperties({'layer': 'surface_obs'});
                                features.push(newFeature);
                            }
                            return features;}, {
                        layerFilter: function(layer) {
                            return layer.getZIndex() === OL_ZINDEXES['surface_obs'] || layer.getZIndex() === OL_ZINDEXES['surface_obs_static']
                        }
                    });
                    COOPSFeatures = this.olMap.forEachFeatureAtPixel(evt.pixel,
                        function(feature) {
                            let features = [];
                            for(const newFeatures of feature.get('features').splice(0, 20)) {
                                const newFeature = newFeatures;
                                newFeature.setId(newFeature.getProperties()['Id']);
                                newFeature.setProperties({'layer': 'co_ops_stations'});
                                features.push(newFeature);
                            }
                            return features; }, {
                            layerFilter: function(layer) {
                            return layer.getZIndex() === OL_ZINDEXES['co_ops_stations']
                        }
                    });
                    if(obsFeatures) {
                        for(const feature of obsFeatures) {
                            newMapClickInfo[key].data.push(feature);
                        }
                    }
                    if(COOPSFeatures) {
                        for(const feature of COOPSFeatures) {
                            const datumURL = "https://api.tidesandcurrents.noaa.gov/mdapi/prod/webapi/stations/" + feature.getId() + ".json?expand=datums,notices&units=english";
                            const parsedDatumData = await getData(datumURL);
                            feature.setProperties({
                                'layer': 'co_ops_stations',
                                'tidal': parsedDatumData.stations[0].tidal,
                                'greatlakes': parsedDatumData.stations[0].greatlakes,
                                'datums': parsedDatumData.stations[0].datums.datums,
                                'nwsID': parsedDatumData.stations[0].shefcode
                            });
                            newMapClickInfo[key].data.push(feature);
                        }
                    }
                    if(!obsFeatures && !COOPSFeatures) {
                        newMapClickInfo[key].data.push("no data")
                    }
                } else if (MULTIPLE_URL_LAYERS.includes(key) || (key === "federal_agency_boundaries" && newMapClickInfo[key].urls !== "military_boundaries")) {
                    // Handle layers that have multiple featureinfo urls to fetch from
                    // NOTE: certain NDFD featureInfoRequests may fail by default like looking for snow_amount
                    // in guam or puertorico, so just need to protect against this adding an empty
                    // features object when doing this (ie. try/catch below)
                    for (const layerName in newMapClickInfo[key].urls) {
                        try {
                            newMapClickInfo[key].data[layerName] = await getData(newMapClickInfo[key].urls[layerName]);
                        } catch (e) {
                            // Empty features not expected by mrms_qpe
                            if (key !== "mrms_qpe") {
                                newMapClickInfo[key].data[layerName] = {features: []}
                            }
                        }
                    }
                } else { // Normal case of one fetch per layer
                    newMapClickInfo[key].data = await getData(newMapClickInfo[key].url);
                }
                this.updateMapClickInfo(newMapClickInfo);
            });
        });

        //listener to handle surface obs refresh
        this.olMap.on('moveend', (evt) => {
            if (this.state.productToggles["surface_obs"]) {
                const obsStationSource = LAYERS['surface_obs'].layers['station_obs_scale6'].sources['station_obs_scale6'].sourceObj.source;
                obsStationSource.refresh();
            }
        })

        //forces a full clear and reload of surface obs every hour
        setInterval(() => {
            if (this.state.productToggles["surface_obs"]) {
                const obsStationSource = LAYERS['surface_obs'].layers['station_obs_scale6'].sources['station_obs_scale6'].sourceObj.source;
                const cachedSource = LAYERS['surface_obs'].layers['cached_stations'].sources['cached_stations'].sourceObj.source;
                const staticSource = LAYERS['surface_obs'].layers['static_clusters'].sources['static_clusters'].sourceObj.source;
                cachedSource.clear();
                staticSource.refresh();
                obsStationSource.refresh();
            }
        }, 3600000);

        //placeholder for tooltip info functionality for surface obs
        //this.olMap.on('pointermove', (evt) => {
        //    this.info.style.visibility = 'hidden';
        //    if (this.state.productToggles["surface_obs"] && this.olMap.getView().getZoom() > 7.5) {
        //        let currentFeature;
        //        const obsStationLayer = LAYERS['surface_obs'].layers['cached_stations'].layerObj;
        //        const pixel = this.olMap.getEventPixel(evt.originalEvent);
        //        const hoverFeature = evt.originalEvent.target.closest('.ol-control') ? undefined :
        //            this.olMap.forEachFeatureAtPixel(pixel, function (feature, layer) {
        //                    return feature;
        //        }, {layerFilter: function(layer) { return layer === obsStationLayer } }
        //        );
        //        if(hoverFeature && hoverFeature.get('features').length === 1) {
        //            this.info.style.left = pixel[0] + 'px';
        //            this.info.style.top = pixel[1] + 'px';
        //            if(hoverFeature !== currentFeature) {
        //                this.info.style.visibility = 'visible';
        //                this.info.innerText = hoverFeature.get('features')[0].getProperties()['stationname'];
        //            } else {
        //                this.info.style.visibility = 'hidden';
        //            }
        //            currentFeature = hoverFeature;
        //        }
        //    }
        //});

        // Time Dimension Controller
        this.timeStateController = new DimensionControl(['time', 'dim_time_reference']);

        // WMS Capabilities Request Handlers
        this.capHandlers = []
        for (const product in LAYERS) {
            if (LAYERS[product].capEvents) {
                this.capHandlers.push({
                    handler: new WMSCapabilitiesHandler(product, LAYERS[product].capUrls, LAYERS[product].capRequestInterval, LAYERS[product].snapThreshold, LAYERS[product].styleLayerNames),
                    events: LAYERS[product].capEvents
                });
            }
        }

        // Bindings for event handling callback functions
        this.updateOlLayerState = this.updateOlLayerState.bind(this);
        this.updateProductToggles = this.updateProductToggles.bind(this);
        this.updateInitializedCaps = this.updateInitializedCaps.bind(this);
        this.updateTimeValues = this.updateTimeValues.bind(this);
        this.updateStyleInfo = this.updateStyleInfo.bind(this);
        this.updateLayerOpacities = this.updateLayerOpacities.bind(this);
        this.updateMapClickPopup = this.updateMapClickPopup.bind(this);
        this.updateMapClickCoords = this.updateMapClickCoords.bind(this);
        this.updateMapClickInfo = this.updateMapClickInfo.bind(this);
        this.updateSelectedTime = this.updateSelectedTime.bind(this);
        this.updateMapClickTime = this.updateMapClickTime.bind(this);
        this.updateCustomLayerInfo = this.updateCustomLayerInfo.bind(this);
        this.setLegendMenuOn = this.setLegendMenuOn.bind(this);
        this.setLayerMenuOn = this.setLayerMenuOn.bind(this);
        this.setRefreshLayers = this.setRefreshLayers.bind(this);
        this.setBasemap = this.setBasemap.bind(this);
        this.updateProductInfo = this.updateProductInfo.bind(this);
    };

    // Call Back Functions - used for updating state in child components

    // Update some or all of state object olLayerState
    // update param must be of form {layerName:{on: t/f, sources: [list of sourceNames]}}
    // Optional Usage: Provide key arg (valid string matching an existing key in obj (ie. a layer name)
    // and pass an update that is a part of the object pointed to by the key. ex. {'on': true}
    // This allows us to update individual aspects of a layer without needing to have
    // the whole object on hand to spread in
    updateOlLayerState(update, key=null) {
        let newVals = update;
        this.setState((state, props) => {
            if (key) {
                newVals = { [key] : Object.assign(state.olLayerState[key], update) };
            }
            return ({
                olLayerState: Object.assign(state.olLayerState, newVals)
            });
        });
    }

    // Update some or all of state object: productToggles
    updateProductToggles(update, rm=false) {
        if (rm) {
            // when rm=true update should be a string containing key of entry you wish to delete from object
            this.setState((state, props) => {
                return ({
                    productToggles: delete state.productToggles[update]
                });
            });
        }
        this.setState((state, props) => {
            return ({
                productToggles: Object.assign(state.productToggles, update)
            });
        });
    }

    // Update some or all of state object: initializedCaps
    updateInitializedCaps(update) {
         this.setState((state, props) => {
            return ({
                initializedCaps: Object.assign(state.initializedCaps, update)
            });
        });
    }

    // Update some or all of state object: timeValues
    updateTimeValues(update) {
        this.setState((state, props) => {
            return ({
                timeValues: Object.assign(state.timeValues, update)
            });
        });
    }

    // Update some or all of state object: styleInfo
    updateStyleInfo(update) {
        this.setState((state, props) => {
            return ({
                styleInfo: Object.assign(state.styleInfo, update)
            });
        });
    }

    // Update some or all of state object: layerOpacities
    updateLayerOpacities(update) {
        this.setState((state, props) => {
            return ({
                layerOpacities: Object.assign(state.layerOpacities, update)
            });
        });
    }

    // Toggle map click popup
    updateMapClickPopup(update) {
        this.setState((state, props) => {
            return ({
                mapClickPopupOn: update
            });
        });
    }

    // Update coordinates from latest map click event
    updateMapClickCoords(x_coord, y_coord) {
        this.setState((state, props) => {
            return ({
                mapClickCoords: {x: x_coord, y: y_coord}
            });
        });
    }
    
    // Update mapClickInfo in state
    updateMapClickInfo(update) {
        this.setState((state, props) => {
            return ({
                mapClickInfo: update
            });
        });
    }

    // Update selectedTime
    updateSelectedTime(update) {
        this.setState((state,props) => {
            return ({
                selectedTime: update
            });
        });
    }

    // Update mapClickTime
    updateMapClickTime(update) {
        this.setState((state,props) => {
            return ({
                mapClickTime: update
            });
        });
    }

    // Update CustomLayerInfo
    updateCustomLayerInfo(update) {
        this.setState((state, props) => {
            return ({
                customLayerInfo: Object.assign(state.customLayerInfo, update)
            });
        });
    }
    // The following two pieces of state were pushed up from map-menus.js
    // It helps manage swaps between popup/layer-menu/legend-menu but it is imperfect. I
    // would like to memorize prev state and restore it when popup is turned off
    // All of this would go smoother if they were managed in a common component like menu-layers.js
    // so TODO: push this back down, AND refactor so that mapClickPopup can be rendered from within menu-layers (if possible)
    // Toggle layer menu on/off
    setLayerMenuOn(update) {
        this.setState((state, props) => {
            return ({
               layerMenuOn: update
            });
        });
    }

    // Toggle legend menu on/off
    setLegendMenuOn(update) {
        this.setState((state, props) => {
            return ({
                legendMenuOn: update
            });
        });
    }

    // Update popupPrevMenuState
    // Requires: "layer", "legend", or "none"
    setPopupPrevMenuState(update) {
        this.setState((state, props) => {
            return ({
                popupPrevMenuState: update
            });
        });
    }

    // Toggle refreshLayers (any change causes layers to be added to map)
    setRefreshLayers(update) {
        this.setState((state, props) => {
            return ({
                refreshLayers: update
            });
        });
    }

    // Switch esri vector tile basemap layer
    setBasemap(name) {
          this.olMap.setLayerGroup(new LayerGroup());

          olms(this.olMap, this.url(name));

          this.setRefreshLayers(!this.state.refreshLayers);
    };

    // Update some or all of state object: productInfo
    // REQUIRES update of the form: {[productName]: {...}} (ONE KEY that is a product name)
    updateProductInfo(update) {
        this.setState((state, props) => {
            const product = Object.keys(update)[0];
            let mergedUpdate = update[product];
            // Merge new keywords list with old one (not currently a concern for other keys, the old will be overwritten by the new)
            mergedUpdate.keywords = [...new Set(state.productInfo[product].keywords.concat(update[product].keywords))];
            mergedUpdate = Object.assign(mergedUpdate, update[product]);
            return ({
                productInfo: Object.assign(state.productInfo, {[product]: mergedUpdate})
            });
        });
    }

    render() {
        const { classes } = this.props;
        const curBreakPoint = getBreakPoint(window.innerWidth);

//        //DEBUG
//        console.log("Current Breakpoint -->", curBreakPoint);

        return (
            <OLMap
                map={this.olMap}
                timeStateController={this.timeStateController}
                capHandlers={this.capHandlers}
                zIndexes={ZINDEXES}
                center={DEFAULT_MAP_POSITIONS[curBreakPoint].center}
                zoom={DEFAULT_MAP_POSITIONS[curBreakPoint].zoom}
                productToggles={this.state.productToggles}
                layerConfig={LAYERS}
                initializedCaps={this.state.initializedCaps}
                setInitializedCaps={this.updateInitializedCaps}
                timeValues={this.state.timeValues}
                updateTimeValues={this.updateTimeValues}
                updateStyleInfo={this.updateStyleInfo}
                layerOpacities={this.state.layerOpacities}
                updateSelectedTime={this.updateSelectedTime}
                customLayerInfo={this.state.customLayerInfo}
                mapClickPopupOn={this.state.mapClickPopupOn}
                olLayerState={this.state.olLayerState}
                updateOlLayerState={this.updateOlLayerState}
                refreshLayers={this.state.refreshLayers}
                updateProductInfo={this.updateProductInfo}
                showZoom={this.showZoom}
            >
                <NCHeader
                    zIndexVal={ZINDEXES.header}
                />
                <MapMenus
                    updateLayerToggles={this.updateProductToggles}
                    layerToggles={this.state.productToggles}
                    updateBasemap={this.setBasemap}
                    initializedCaps = {this.state.initializedCaps}
                    styleInfo={this.state.styleInfo}
                    zIndexVal = {ZINDEXES.layer_menu}
                    layerOpacities={this.state.layerOpacities}
                    updateLayerOpacities={this.updateLayerOpacities}
                    mapClickPopupOn={this.state.mapClickPopupOn}
                    updateMapClickPopup={this.updateMapClickPopup}
                    updateCustomLayerInfo={this.updateCustomLayerInfo}
                    customLayerInfo={this.state.customLayerInfo}
                    setLayerMenuOn={this.setLayerMenuOn}
                    layerMenuOn={this.state.layerMenuOn}
                    setLegendMenuOn={this.setLegendMenuOn}
                    legendMenuOn={this.state.legendMenuOn}
                    olLayerState={this.state.olLayerState}
                    updateOlLayerState={this.updateOlLayerState}
                    productInfo={this.state.productInfo}
                />
                { this.state.mapClickPopupOn ?
                    <MapClickPopup
                        map={this.olMap}
                        zIndexVal = {ZINDEXES.map_click_popup}
                        layerConfig={LAYERS}
                        updateMapClickPopup={this.updateMapClickPopup}
                        mapClickCoords={this.state.mapClickCoords}
                        mapClickInfo={this.state.mapClickInfo}
                        mapClickTime={this.state.mapClickTime}
                        olLayerState={this.state.olLayerState}
                        styleInfo={this.state.styleInfo}
                        setLayerMenuOn={this.setLayerMenuOn}
                        setLegendMenuOn={this.setLegendMenuOn}
                        popupPrevMenuState={this.state.popupPrevMenuState}
                        productToggles={this.state.productToggles}
                    />
                    : null
                }
                <NCFooter zIndexVal={ZINDEXES.footer} />
            </OLMap>
        );
    };
}

//export default withStyles(useStyles, { withTheme: true })(NCMapViewerApp);
export default NCMapViewerApp;

/** getBreakPoint uses breakpoints info imported from config to identify the breakpoint used for a specific window pixel width
* Args: windowWidth - width of window in pixels
* Returns: string containing name of current breakpoint
* Requires: BREAKPOINT_VALUES must be defined globally and should contain xs, sm, md, lg, xl keys for meaningful results
**/
function getBreakPoint(windowWidth) {
    if (windowWidth < BREAKPOINT_VALUES.sm) return "xs";
    if (windowWidth < BREAKPOINT_VALUES.md) return "sm";
    if (windowWidth < BREAKPOINT_VALUES.lg) return "md";
    if (windowWidth < BREAKPOINT_VALUES.xl) return "lg";
    return "xl";
}

async function getData(url) {
    const response = await fetch(url);
    const data = await response.text();
    const parsedData = JSON.parse(data);
    return parsedData;
}
